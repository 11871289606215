main{
    padding:var(--margin);
    padding-top: 20vh;
    min-height:100vh;
}

h1{
    font-size: 46px;
}
h2{
    font-size: 30px;
    margin-top:2em;
}

p, ul {
    font-size: 18px;
}
.container {
    display: flex;
    justify-content: space-between;
    padding-top: 20vh;
    main {
        padding-top: var(--margin);
        max-width: 70vw;
    }
    p {
        max-width: 100%;
    }
}
.news {
    h3{
        font-family: var(--serif);
        font-weight: 300;
        margin:0.5em 0;
    }
    small {
        font-size: 0.7rem;
        font-weight: 400;
        margin: 0;
    }
    .small {
        font-weight: 400;
        font-size: 0.85rem;
        letter-spacing: -0.02em;
    }
    .article{
        display: block;
        padding: 20px;
        margin-bottom: calc(var(--gap)/2);
        border-radius: 10px;
        transition: transform 200ms;
        border: 1px solid #ECECE0;
    }
    .article:hover{
        background: var(--yellow);
        transform:translateX(20px);
        border-color: transparent;
    }
    .logo {
        object-fit: contain;
        width: 120px;
        height: 60px;
    }
}
.sidebar {
    flex-direction: column;
    justify-content: flex-start;
    h2 {
        font-size: 32px;
    }
    h3 {
        font-size: 18px;
    }
    .logo {
        object-fit: contain;
        width: 60px;
        height: 30px;
    }
}
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        main, .sidebar {
            width: initial;
            max-width: 100%;
        }
    }
}
